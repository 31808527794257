import i18n from 'i18next';
import { Spine } from 'pixi-spine';
import { Container, isMobile, Loader, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setCurrentBonus, setIsContinueAutoSpinsAfterFeature, setIsDuringBigWinLoop } from '../../../gql/cache';
import type Animation from '../../animations/animation';
import { AbstractContainer } from '../../components/AbstractContainer';
import { TextField } from '../../components/TextField';
import { eventEmitter } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';
import { titleTextStyle, valueTextStyle } from '../textStyles';

export class FreeSpinsPopup extends Popup {
  protected background!: Sprite;

  protected animation!: Spine;

  protected title!: TextField;

  protected rounds!: TextField;

  protected pressToContinueText!: TextField;

  protected textContainer!: AbstractContainer;

  public fadeOutAnimation!: Animation;

  public fadeInAnimation!: Animation;

  public backgroundFadeInAnimation!: Animation;

  public backgroundFadeOutAnimation!: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.initBackground();
    this.initAnimation();
    this.init();
  }

  private initAnimation(): void {
    this.animation = new Spine(Loader.shared.resources['fs-popup']!.spineData!);
  }

  private initBackground(): void {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.background.alpha = 0.5;
  }

  private initTitle(): void {
    this.title = new TextField(i18n.t('FREE SPINS'), 900, 400, titleTextStyle);
    this.title.text.y = -170;
    this.title.text.anchor.set(0.5, 0.5);
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.animation);
    this.initTitle();
    this.initValueText();
    const content = new Container();
    content.addChild(this.title.text, this.rounds.text);
    this.animation.skeleton.findSlot('place_holder').currentSprite.texture = Texture.EMPTY;
    this.animation.skeleton.findSlot('place_holder').currentSprite.addChild(content);
  }

  private initValueText(): void {
    this.rounds = new TextField('', 500, 400, valueTextStyle);
    this.rounds.text.anchor.set(0.5, 0.5);
    this.rounds.text.y = 50;
  }

  public override show(_props?: PopupProps): void {
    AudioApi.play({ type: ISongs.FreeSpinStart });
    setTimeout(() => {
      AudioApi.play({ type: ISongs.FreeSpinsBackground });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.FreeSpinsBackground);
      }
    }, 4000);

    this.rounds.text.text = `${setCurrentBonus().rounds}`;
    this.visible = true;
    this.showAnimationTrack = this.animation.state.setAnimation(0, 'FS_popup_intro', false);
    this.animation.state.addAnimation(0, 'FS_popup_cycle', true, 0);
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventEmitter.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    AudioApi.stop({ type: ISongs.FreeSpinStart });
    AudioApi.play({ type: ISongs.FreeSpinClose });
    clearTimeout(this.autoSkipDelay);
    eventEmitter.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.hideAnimation();
  };

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    const factor = (isMobile.any && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.animation.scale.set(factor);
    this.position.set(width / 2, height / 2);
    this.animation.y = isMobile.any && !isLandscape ? -50 : 0;
  }

  public hideAnimation(): void {
    this.animation.state.setAnimation(0, 'FS_popup_outro', false);
    this.animation.state.addListener({
      complete: (_entry: { trackIndex: number }) => {
        this.visible = false;
        eventEmitter.emit(EventTypes.START_FREE_SPINS);
        this.animation.state.addEmptyAnimation(0, 0, 0);
      },
    });
  }
}
