import AudioApi from '@money.energy/audio-api';
import { TPlay } from '@money.energy/audio-api/dist/d';
import { ISongs, SlotId } from '../config';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  eventEmitter,
  MEGA_WIN_AMOUNT_LIMIT,
  REELS_AMOUNT,
  SLOTS_PER_REEL_AMOUNT,
  SUPER_WIN_AMOUNT_LIMIT,
  WinStages,
} from '../game/config';
import { Icon, MysteryMatrix } from '../game/d';
import { BonusState, buyBonusBonusesId, EventTypes, GameMode, ISettledBet, ReelSet } from '../global.d';
import { setBetAmount, setIsDuringBigWinLoop, setIsSuspended, setRestoreGame, setSlotConfig } from '../gql/cache';
import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

export const normalizePosition = (size: number, pos: number): number => {
  while (pos < 0) pos += size;
  return pos % size;
};

export const getBonusIdByBonus = (bonusState: BonusState): string => {
  return buyBonusBonusesId[bonusState as BonusState]!;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isBuyBonusMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_BONUS;
};

export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < SUPER_WIN_AMOUNT_LIMIT) return WinStages.SuperWin;
  return WinStages.UltraWin;
};

export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lines.length)) / 100;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotStopped,
  isPopupOpened,
  isAutoPlay,
  isTransitioning,
  countUpInProgress,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened: boolean;
  isAutoPlay: boolean;
  isTransitioning: boolean;
  countUpInProgress: boolean;
}): boolean => {
  if (isBuyBonusMode(gameMode) && isFreeSpinsWin) {
    return false;
  }
  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isSpinInProgress && countUpInProgress) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  if (isAutoPlay) {
    return false;
  }

  if (isTransitioning) {
    return false;
  }

  return true;
};

export const getBackgroundSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.Background;
    case GameMode.FREE_SPINS:
      return ISongs.FreeSpinsBackground;
    default:
      return ISongs.Background;
  }
};

export const getSlotOrderBySlotId = (slotId: SlotId): number => {
  switch (slotId) {
    case SlotId.SC1:
      return 12;
    case SlotId.WL:
      return 11;
    case SlotId.F:
      return 10;
    case SlotId.G:
      return 9;
    case SlotId.H:
      return 8;
    case SlotId.I:
      return 7;
    case SlotId.J:
      return 6;
    case SlotId.A:
      return 5;
    case SlotId.B:
      return 4;
    case SlotId.C:
      return 3;
    case SlotId.D:
      return 2;
    case SlotId.E:
      return 1;
    default:
      return 0;
  }
};

export const getLayerOrderByName = (name: string): number => {
  switch (name) {
    case 'Background':
      return 8;
    case 'GameWrapper':
      return 9;
    case 'Backdrop':
      return 10;
    case 'BigWinContainer':
      return 11;
    case 'FreeSpins Popup':
      return 12;
    case 'ControlBtn':
      return 9;
    case 'CloseBtn':
      return 10;
    default:
      return 0;
  }
};

export const fallBackReelPosition = () => {
  eventEmitter.emit(EventTypes.ROLLBACK_REELS);
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME;
};

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return !isLandscape && _deviceWidth >= 768 && _deviceWidth <= 1366;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200 && _deviceWidth < 1400;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isLandscape && _deviceWidth < 950;
};

export const playWinSound = (winCoinAmount: number): void => {
  const multiplier = normalizeCoins(winCoinAmount) / normalizeCoins(setBetAmount());
  if (multiplier > 5 && multiplier < 10) {
    AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
  }
  if (multiplier > 3 && multiplier <= 5) {
    AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
  }
  if (multiplier >= 1 && multiplier <= 3) {
    AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
  }
};

export const handleChangeRestriction = (mode: GameMode): void => {
  if (setRestoreGame()) {
    setIsSuspended(false);
    AudioApi.unSuspend();
    AudioApi.processRestriction({
      restricted: false,
    });
    eventEmitter.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
  } else {
    AudioApi.unSuspend();
    AudioApi.processRestriction({
      restricted: false,
    });
    const list: TPlay[] = [];
    if (setIsDuringBigWinLoop()) {
      list.push({ type: ISongs.BigWin_Loop });
    }
    switch (mode) {
      case GameMode.BASE_GAME:
        list.push({ type: ISongs.Background }, { type: ISongs.LibraryAmbience, volume: 0 });
        break;
      case GameMode.FREE_SPINS:
        list.push({ type: ISongs.FreeSpinsBackground, stopImmediately: [ISongs.Background, ISongs.LibraryAmbience] });
        break;
      default:
        list.push({ type: ISongs.Background }, { type: ISongs.LibraryAmbience, volume: 0 });
        break;
    }
    AudioApi.playlist({ list });
  }
};

export const playBackgroundAudio = (gameMode: GameMode) => {
  switch (gameMode) {
    case GameMode.BASE_GAME:
      AudioApi.play({ type: ISongs.Background });
      break;
    case GameMode.FREE_SPINS:
      AudioApi.play({ type: ISongs.FreeSpinsBackground });
      break;
    default:
      AudioApi.play({ type: ISongs.Background });
  }
  if (setIsDuringBigWinLoop()) {
    AudioApi.play({ type: ISongs.BigWin_Loop });
  }
};

const normalize = (coord: number, layout: string[]) => {
  return coord < 0 ? layout.length - (Math.abs(coord) % layout.length) : coord % layout.length;
};

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
  mysteryMatrix,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
  mysteryMatrix: MysteryMatrix[];
}): Icon[] => {
  const cols = REELS_AMOUNT;
  const rows = SLOTS_PER_REEL_AMOUNT;
  const spinResult = [...(Array(cols * rows) as Icon[])].map((_, index) => {
    const row = Math.floor(index / cols);
    const column = index % cols;
    const layout = reelSet.layout[column as number];

    const initialCoord = reelPositions[column as number];
    const coord = (initialCoord as number) + row - 1;
    const reelSetIndex = normalize(coord, layout as string[]);
    const icon = JSON.parse(
      JSON.stringify(icons.find((icon) => icon.id === reelSet.layout[column as number]![reelSetIndex]) || icons[0]),
    );
    icon.matrixIndex = reelSetIndex;
    icon.columnId = column;
    if (mysteryMatrix && mysteryMatrix[index]?.changeTo) {
      icon.changeTo = mysteryMatrix[index]?.changeTo;
      icon.mysteryType = mysteryMatrix[index]?.mysteryType;
    }
    return icon;
  }) as Icon[];
  return spinResult;
};
