import i18n from 'i18next';
import * as _ from 'lodash';
import { Spine } from 'pixi-spine';
import { isMobile, ITextStyle, Loader, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs } from '../../../config';
import { BonusState, EventTypes, IBonus } from '../../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../../gql/cache';
import type { IBetSettings } from '../../../gql/d';
import { getBetsSetting } from '../../../gql/fromFragment';
import { ResourceTypes } from '../../../resources.d';
import { getBonusIdByBonus, normalizeCoins, showCurrency } from '../../../utils';
import {
  betValueStyle,
  rageButtonActiveTextStyle,
  rageButtonHoverTextStyle,
  titleBaseTextStyle,
  totalCostBaseTextStyle,
  totalValuePressedStyle,
  totalValueStyle,
} from '../../buyBonus/textStyles';
import { FeatureButton } from '../../components/FeatureButton';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { BONUS_POPUP_HEIGHT, BONUS_POPUP_WIDTH, eventEmitter } from '../../config';
import { Popup } from '../popup';
import { PopupModule } from '../PopupModule';

class BuyBonusPopup extends Popup {
  private background!: Sprite;

  private animation!: Spine;

  private freeSpinsButton: FeatureButton;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: TextField;

  private amountText: TextField;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private betAmountBackplate: Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private betValue: TextField;

  private freeSpinsTotalCostValue: TextField;

  private isNoFunds: boolean;

  private balance: number;

  private bonusState: BonusState = BonusState.FREE_SPINS;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.x = 89;
    this.y = 59;
    this.visible = false;
    this.coinMultiplier = setSlotConfig().lineSets[0]!.coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.background = this.initBackground();
    this.titleText = this.initTitle();
    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.betValue = this.initBetValue();
    this.freeSpinsTotalCostValue = this.initfreeSpinsTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.freeSpinsButton = this.initFreeSpinsButton();
    this.okBtn = this.initOkBtn();
    // this.animation = this.initAnimation();
    this.init();
    this.scale.set(0.88);
    eventEmitter.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventEmitter.on(EventTypes.START_BUY_BONUS_ROUND, () => {
      PopupModule.the.closeCurrentPopup();
    });
    eventEmitter.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
  }

  public override show(): void {
    super.show();
    this.closeAllAnimationsInSlot();
    this.visible = true;
  }

  public close(): void {
    AudioApi.play({ type: ISongs.UI_ButtonClose });
    this.visible = false;
    eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, false);
    eventEmitter.emit(EventTypes.CLOSE_POPUP);
  }

  private init(): void {
    this.addChild(this.background);
    // this.background.addChild(this.animation);
    this.addChild(this.betAmountBackplate);
    this.addChild(this.titleText.getText());
    this.addChild(this.amountText.getText());
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.betValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.freeSpinsButton);
  }

  private initAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources['buyBonusPopups']!.spineData!);
    animation.state.setAnimation(0, 'buy_feature_popup_dark_to_light', true);
    animation.x = 640;
    animation.y = 465;
    animation.scale.set(1.35);
    return animation;
  }

  private initBackground(): Sprite {
    const background = new Sprite(Texture.from(ResourceTypes.betMinusBtnHover));
    background.width = isMobile.any ? 1150 : BONUS_POPUP_WIDTH;
    background.height = isMobile.any ? 925 : BONUS_POPUP_HEIGHT;
    background.x = isMobile.any ? 40 : 90;
    background.y = isMobile.any ? 45 : 40;

    return background;
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyBonusTitleBase', { spinsNumber: 10 }),
      1100,
      150,
      titleBaseTextStyle as Partial<ITextStyle>,
    );
    title.text.y = isMobile.any ? 165 : 170;
    title.text.x = 640;
    title.text.anchor.set(0.5, 0.5);

    return title;
  }

  private initAmountText(): TextField {
    const amountText = new TextField(
      i18n.t<string>('buyBonusBetPerGame'),
      400,
      100,
      totalCostBaseTextStyle as Partial<ITextStyle>,
    );
    amountText.text.y = 700;
    amountText.text.x = 650;
    amountText.text.anchor.set(0.5, 0);

    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    minusBtn.y = 810;
    minusBtn.x = 390;
    minusBtn.height = 69;
    minusBtn.width = 59;
    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.y = 810;
    plusBtn.x = 870;
    plusBtn.height = 69;
    plusBtn.width = 59;

    return plusBtn;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.betPlusBtn));
    input.y = 810;
    input.x = 630;
    input.width = 400;
    input.height = 75;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initBetValue(): TextField {
    const betValue = new TextField(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      300,
      100,
      betValueStyle as Partial<ITextStyle>,
    );
    betValue.text.y = 785;
    betValue.text.x = 635;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initfreeSpinsTotalCostValue(): TextField {
    const betValue = new TextField(
      this.getTotalCost(BonusState.FREE_SPINS),
      300,
      100,
      totalValueStyle as Partial<ITextStyle>,
    );
    betValue.text.y = 105;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.width = 116;
    cancelBtn.height = 116;
    cancelBtn.y = 812;
    cancelBtn.x = isMobile.any ? 189 : 221;
    return cancelBtn;
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.UI_ButtonClose });
    eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, false);
    PopupModule.the.closeCurrentPopup();
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private initFreeSpinsButton(): FeatureButton {
    const freeSpinsButton = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
        textStyle: totalValuePressedStyle as Partial<ITextStyle>,
        subTitleTextStyle: totalValuePressedStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
        textStyle: rageButtonHoverTextStyle as Partial<ITextStyle>,
        // subTitleTextStyle: valueButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
        textStyle: rageButtonHoverTextStyle as Partial<ITextStyle>,
        // subTitleTextStyle: valueButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
        textStyle: totalValuePressedStyle as Partial<ITextStyle>,
        // subTitleTextStyle: totalValuePressedStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
        textStyle: totalValuePressedStyle as Partial<ITextStyle>,
        // subTitleTextStyle: valueButtonPressedTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: () => this.handleBaseBtnClick(),
      onTouchStart: () => this.handleBaseBtnClick(),
      textFiled: this.initFreeSpinsButtonText(),
      // subTitle: this.freeSpinsTotalCostValue,
    });
    freeSpinsButton.y = 475;
    freeSpinsButton.x = 885;

    return freeSpinsButton;
  }

  private handleBaseBtnClick(): void {
    this.changeBonusState(BonusState.FREE_SPINS);
    this.titleText.setText(
      i18n.t<string>('buyBonusTitleBase', {
        spinsNumber: 10,
      }),
    );
    this.amountText.setStyle(totalCostBaseTextStyle);
    this.freeSpinsButton.turnOn();
    this.updateBets();
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betPlusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
      // textFiled: this.initOkButtonText(),
    });
    okBtn.y = isMobile.any ? 812 : 812;
    okBtn.x = isMobile.any ? 1049 : 1029;
    okBtn.width = 116;
    okBtn.height = 116;
    return okBtn;
  }

  private initFreeSpinsButtonText(): TextField {
    const freeSpinsText = new TextField(
      i18n.t<string>('freeSpinsButtonText'),
      275,
      50,
      rageButtonActiveTextStyle as Partial<ITextStyle>,
    );
    freeSpinsText.text.anchor.set(0.5, 0.5);
    freeSpinsText.text.y = 80;
    return freeSpinsText;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    const isMinBet = this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet;
    if (isMinBet) {
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    const isMaxBet = this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet;
    if (isMaxBet) {
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.UI_BetChange });
    }
  };

  private changeBonusState(state: BonusState): void {
    this.bonusState = state;
    AudioApi.play({ type: ISongs.UI_ButtonPress });
    this.handleDisable();
  }

  private updateBets(): void {
    this.betValue.text.text = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
    this.freeSpinsTotalCostValue.text.text = this.getTotalCost(BonusState.FREE_SPINS);
  }

  private getTotalCost = (bonusState: BonusState): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount(bonusState)),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (bonusState: BonusState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByBonus(bonusState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleClickOk = (): void => {
    AudioApi.play({ type: ISongs.UI_ButtonPress });
    if (!this.isNoFunds) {
      // PopupController.the.openPopup(PopupTypes.BUY_BONUS_CONFIRMATION, {
      //   totalCost: this.getTotalCost(this.bonusState),
      //   coinAmount: this.betSettings.bets[this.betAmount] as number,
      //   bonusState: this.bonusState,
      // });
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    const isMinBet = bet === this.betSettings!.minBet;
    const isMaxBet = bet === this.betSettings!.maxBet;

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount(this.bonusState));
    if (this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount(BonusState.FREE_SPINS))) {
      this.freeSpinsButton.disable();
    } else {
      this.freeSpinsButton.enable();
    }
    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }
    if (isMinBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (isMaxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
  };

  private closeAllAnimationsInSlot(): void {
    eventEmitter.emit(EventTypes.HIDE_COUNT_UP);
  }
}

export default BuyBonusPopup;
