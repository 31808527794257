import type { TAudioSprite } from '@money.energy/audio-api/dist/d';

export enum ISongs {
  Background = '013_01_Background',
  BigWin_Loop = '013_08_BigWin_Loop',
  BigWin_End = '013_09_BigWin_End',
  BigWin = '013_10_BigWin',
  MegaWin = '013_11_MegaWin',
  SmallWin = '013_14_SmallWin',
  MediumWin = '013_15_MediumWin',
  HighWin = '013_16_HighWin',
  CountUp_Stop = 'CountUp_Stop',
  CountUp_Loop = 'CountUp_Loop',
  UI_AutoSpin = 'UI_AutoSpin',
  UI_BetChange = 'UI_BetChange',
  UI_ButtonClose = 'UI_ButtonClose',
  UI_ButtonPress = 'UI_ButtonPress',
  UI_ButtonHover = 'UI_ButtonHover',
  UI_MenuOpen = 'UI_MenuOpen',
  UI_SpinStart = 'UI_SpinStart',
  UI_SpinStop = 'UI_SpinStop',
  LibraryAmbience = 'LibraryAmbience',
  ScatterAppear_1 = 'ScatterAppear_1',
  ScatterAppear_2 = 'ScatterAppear_2',
  ScatterAppear_3 = 'ScatterAppear_3',
  ScatterWin = 'ScatterWin',
  UltraWin = 'UltraWin',
  SuperWin = 'SuperWin',
  BlueNormalSymbolOpen = 'BlueNormalSymbolOpen',
  BlueSpecialSymbolOpen = 'BlueSpecialSymbolOpen',
  BrownNormalSymbolOpen = 'BrownNormalSymbolOpen',
  GoldNormalSymbolOpen = 'GoldNormalSymbolOpen',
  GoldSpecialSymbolOpen = 'GoldSpecialSymbolOpen',
  FreeSpinClose = 'FreeSpinClose',
  FreeSpinEnd = 'FreeSpinEnd',
  FreeSpinStart = 'FreeSpinStart',
  FreeSpinsBackground = 'FreeSpinsBackground',
}

export const audioSprite: TAudioSprite = {
  [ISongs.Background]: [0, 93913.10657596373, true],
  [ISongs.BigWin]: [95000, 4662.857142857149],
  [ISongs.BigWin_End]: [101000, 9765.442176870749],
  [ISongs.BigWin_Loop]: [112000, 31222.13151927437, true],
  [ISongs.BlueNormalSymbolOpen]: [145000, 4961.8140589569175],
  [ISongs.BlueSpecialSymbolOpen]: [151000, 6691.700680272106],
  [ISongs.BrownNormalSymbolOpen]: [159000, 4404.535147392295],
  [ISongs.CountUp_Loop]: [165000, 449.8866213151871, true],
  [ISongs.CountUp_Stop]: [167000, 2628.208616780057],
  [ISongs.FreeSpinClose]: [171000, 2970.702947845808],
  [ISongs.FreeSpinEnd]: [175000, 11210.884353741505],
  [ISongs.FreeSpinStart]: [188000, 11193.469387755102],
  [ISongs.FreeSpinsBackground]: [201000, 101053.24263038552, true],
  [ISongs.GoldNormalSymbolOpen]: [304000, 4613.514739229004],
  [ISongs.GoldSpecialSymbolOpen]: [310000, 5701.9501133787],
  [ISongs.HighWin]: [317000, 4964.716553287985],
  [ISongs.LibraryAmbience]: [323000, 261028.57142857148, true],
  [ISongs.MediumWin]: [586000, 4961.814058956861],
  [ISongs.MegaWin]: [592000, 3307.3922902494814],
  [ISongs.ScatterWin]: [597000, 6021.224489795941],
  [ISongs.SmallWin]: [605000, 3606.3492063492504],
  [ISongs.SuperWin]: [610000, 2999.7278911564536],
  [ISongs.UI_AutoSpin]: [614000, 253.96825396825307],
  [ISongs.UI_BetChange]: [616000, 104.4897959183686],
  [ISongs.UI_ButtonClose]: [618000, 198.82086167802981],
  [ISongs.UI_ButtonHover]: [620000, 114.64852607707599],
  [ISongs.UI_ButtonPress]: [622000, 105.94104308387386],
  [ISongs.UI_MenuOpen]: [624000, 129.16099773241285],
  [ISongs.UI_SpinStart]: [626000, 187.21088435376032],
  [ISongs.UI_SpinStop]: [628000, 85.6235827664591],
  [ISongs.UltraWin]: [630000, 4360.997732426313],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.Background]: 0.4,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.BigWin]: 0.6,
  [ISongs.MegaWin]: 0.6,
  [ISongs.SmallWin]: 0.6,
  [ISongs.MediumWin]: 0.6,
  [ISongs.HighWin]: 0.6,
  [ISongs.CountUp_Stop]: 0.3,
  [ISongs.CountUp_Loop]: 0.4,
  [ISongs.UI_AutoSpin]: 0.4,
  [ISongs.UI_BetChange]: 0.4,
  [ISongs.UI_ButtonClose]: 0.4,
  [ISongs.UI_ButtonPress]: 0.4,
  [ISongs.UI_ButtonHover]: 0.3,
  [ISongs.UI_MenuOpen]: 0.4,
  [ISongs.UI_SpinStart]: 0.2,
  [ISongs.UI_SpinStop]: 0.2,
  [ISongs.LibraryAmbience]: 0.4,
  [ISongs.ScatterAppear_1]: 0.2,
  [ISongs.ScatterAppear_2]: 0.2,
  [ISongs.ScatterAppear_3]: 0.2,
  [ISongs.ScatterWin]: 0.6,
  [ISongs.UltraWin]: 0.6,
  [ISongs.SuperWin]: 0.6,
  [ISongs.GoldNormalSymbolOpen]: 0.6,
  [ISongs.GoldSpecialSymbolOpen]: 0.6,
  [ISongs.BrownNormalSymbolOpen]: 0.6,
  [ISongs.BlueNormalSymbolOpen]: 0.6,
  [ISongs.BlueSpecialSymbolOpen]: 0.6,
  [ISongs.FreeSpinClose]: 0.4,
  [ISongs.FreeSpinEnd]: 0.4,
  [ISongs.FreeSpinStart]: 0.4,
  [ISongs.FreeSpinsBackground]: 0.4,
};
