import { Spine } from 'pixi-spine';
import { Container, Loader, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import {
  ISongs,
  MAPPED_BLURRED_SYMBOLS,
  MAPPED_MYSTERY_SYMBOLS,
  MAPPED_SYMBOLS,
  MAPPED_SYMBOLS_LAND_ANIMATIONS,
  SlotId,
} from '../../config';
import { EventTypes } from '../../global.d';
import { setBetResult } from '../../gql/cache';
import { getBetResult, getFromMappedSymbol, getFromMysteryMappedSymbol, nextTick } from '../../utils';
import { eventEmitter, SLOT_HEIGHT, SLOT_SCALE, SLOT_WIDTH, SLOTS_PER_REEL_AMOUNT } from '../config';
import { MysteryAnimations, MysteryType } from '../d';

class Slot extends Container {
  public id: number;

  public slotId: SlotId;

  private reelId: number | undefined;

  public mysteryType: MysteryType | undefined;

  public textureSlotId: SlotId;

  public slot: Sprite;

  private landAnimation!: Spine;

  private mysteryAnimation!: Spine;

  constructor(id: number, slotId: SlotId, reelId?: number, mysteryType?: MysteryType) {
    super();
    this.id = id;
    this.slotId = slotId;
    this.reelId = reelId;
    this.mysteryType = mysteryType;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = SLOT_WIDTH / 2;
    this.zIndex = 1;
    this.slotId = slotId;
    this.slot = this.initSlot();
    this.addChild(this.slot);
  }

  private initSlot(): Sprite {
    const slot = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, this.slotId)));
    slot.anchor.set(0.5, 0.5);
    slot.scale.set(SLOT_SCALE);
    return slot;
  }

  public changeTexture(slotId: SlotId, mysteryType?: MysteryType): void {
    if (mysteryType) {
      this.mysteryType = mysteryType;
      this.slot.texture = Texture.from(getFromMysteryMappedSymbol(MAPPED_MYSTERY_SYMBOLS, mysteryType));
    } else {
      this.slot.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    }
    this.textureSlotId = slotId;
  }

  public changeSlot(slotId: SlotId): void {
    this.changeTexture(slotId);
    this.slotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      if (this.mysteryType) {
        this.slot.texture = Texture.from(MAPPED_MYSTERY_SYMBOLS[this.mysteryType].default);
      } else {
        this.slot.texture = Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId].default);
      }
    } else {
      if (this.mysteryType) {
        this.slot.texture = Texture.from(MAPPED_MYSTERY_SYMBOLS[this.mysteryType].default);
      } else {
        this.slot.texture = Texture.from(MAPPED_SYMBOLS[this.textureSlotId].default);
      }
    }
  }

  public onSlotStopped(): void {
    // @TODO ADD add sound/animation on slot stop
    this.playLandAnim();
  }

  public playMysteryAnimation(changeTo: SlotId, mysteryType: MysteryType): void {
    if (!this.mysteryType) return;
    const betResult = getBetResult(setBetResult());
    const includesSpecialReveal = betResult.bet.data.features?.mystery?.mysteryMatrix.find(
      (e) => e.changeTo === SlotId.SC1 || e.changeTo === SlotId.WL,
    );

    const isSpecial = changeTo === SlotId.SC1 || changeTo === SlotId.WL;
    const changeToTexture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, changeTo!));
    this.mysteryAnimation = new Spine(Loader.shared.resources!['mystery'!]!.spineData!);
    const placeholder = this.mysteryAnimation.skeleton.findSlot('place_holder').currentSprite;
    placeholder.texture = changeToTexture;
    const { animation, sound } = this.mysterySymbolAnimations(isSpecial, mysteryType!);

    this.mysteryAnimation.state.addListener({
      start: () => {
        nextTick(() => {
          this.slot.visible = false;
        });
      },
      complete: () => {
        nextTick(() => {
          this.changeTexture(changeTo!);
          if (this.mysteryAnimation.state) this.mysteryAnimation.destroy();
          this.slot.visible = true;
          if ((includesSpecialReveal && isSpecial) || (!includesSpecialReveal && !isSpecial)) {
            eventEmitter.emit(EventTypes.MYSTERY_SYMBOLS_REVEAL_END);
          }
        });
      },
    });
    this.mysteryAnimation.state.setAnimation(0, animation, false);
    AudioApi.stop({ type: sound });
    AudioApi.play({ type: sound });
    this.addChild(this.mysteryAnimation);
  }

  private playLandAnim(): void {
    if (MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]) {
      const animationSrc = MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.src;
      const animationName = MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.animation;
      this.landAnimation = new Spine(Loader.shared.resources![animationSrc!]!.spineData!);

      this.landAnimation.state.addListener({
        start: () => {
          nextTick(() => {
            this.slot.visible = false;
          });
        },
        complete: () => {
          nextTick(() => {
            if (this.landAnimation.state) this.landAnimation.destroy();
            this.slot.visible = true;
            eventEmitter.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
          });
        },
      });
      this.landAnimation.state.setAnimation(0, animationName!, false);
      this.addChild(this.landAnimation);
    } else {
      eventEmitter.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
    }
  }

  private mysterySymbolAnimations(isSpecial: boolean, type: MysteryType): { animation: string; sound: string } {
    switch (type) {
      case MysteryType.GOLDEN:
        return isSpecial
          ? {
              animation: MysteryAnimations.GoldSpecial,
              sound: ISongs.GoldSpecialSymbolOpen,
            }
          : {
              animation: MysteryAnimations.GoldNormal,
              sound: ISongs.GoldNormalSymbolOpen,
            };

      case MysteryType.FREE_SPINS:
        return isSpecial
          ? {
              animation: MysteryAnimations.BlueSpecial,
              sound: ISongs.BlueSpecialSymbolOpen,
            }
          : {
              animation: MysteryAnimations.BlueNormal,
              sound: ISongs.BlueNormalSymbolOpen,
            };

      case MysteryType.DEFAULT:
        return {
          animation: MysteryAnimations.BrownNormal,
          sound: ISongs.BrownNormalSymbolOpen,
        };

      default:
        return { animation: '', sound: '' };
    }
  }
}

export default Slot;
