import { isMobile, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import { setIsAutoSpins, setIsLeftHandMode, setIsPopupOpeningInProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { AbstractContainer } from '../components/AbstractContainer';
import { TextField } from '../components/TextField';
import {
  BONUS_BTN_CONTAINER_POSITION_X,
  BONUS_BTN_HEIGHT,
  buyBonusClickTextStyle,
  buyBonusDisableTextStyle,
  buyBonusHoverTextStyle,
  buyBonusTextStyle,
  eventEmitter,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  PopupTypes,
} from '../config';
import { PopupModule } from '../popups/PopupModule';

class BuyBonusButton extends AbstractContainer {
  private btn: Sprite;

  private text: TextField;

  isDisabled: boolean;

  resizedWidth: number;

  resizedHeight: number;

  isLeftHandMode: boolean;

  constructor() {
    super();
    this.x = -150;
    this.y = 300;
    this.isDisabled = false;
    this.text = this.initBuyBonusText();
    this.btn = this.initBuyBonusBtn();
    this.resizedWidth = 0;
    this.resizedHeight = 0;
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    eventEmitter.on(EventTypes.DISABLE_BUY_BONUS_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventEmitter.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });
  }

  protected override onGameModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initBuyBonusBtn() {
    const btn = new Sprite(Texture.from(ResourceTypes.betPlusBtnHover));
    btn.anchor.set(0.5);
    btn.width = 185;
    btn.height = 185;
    btn.interactive = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.UI_ButtonHover });
        this.btn.texture = Texture.from(ResourceTypes.betPlusBtnHover);

        this.text.text.style = buyBonusHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.betPlusBtnHover);

        this.text.text.style = buyBonusTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.betPlusBtnHover);

        this.text.text.style = buyBonusClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.betPlusBtnHover);

        this.text.text.style = buyBonusTextStyle;
      }
    });
    return btn;
  }

  private initBuyBonusText(): TextField {
    const text = new TextField(i18n.t<string>('buyBonusBtn'), 95, 200, buyBonusTextStyle);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2 - 5);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_BONUS);
      // AudioApi.play({ type: ISongs.BuyButton });
      eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, true);
      PopupModule.the.openPopup(PopupTypes.BUY_BONUS);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = Texture.from(ResourceTypes.betPlusBtnHover);

      this.text.text.style = buyBonusDisableTextStyle;
    } else {
      this.btn.texture = Texture.from(ResourceTypes.betPlusBtnHover);

      this.text.text.style = buyBonusTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  protected override resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.resizedWidth < this.resizedHeight && isMobile.any) {
      this.scale.set(1.3);
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH - 140 : 130;
      this.y = GAME_CONTAINER_HEIGHT + BONUS_BTN_HEIGHT + 93;
      return;
    }
    this.scale.set(1);
    if (
      // this.resizedWidth >= parseInt(variables['breakpointTabletLandscapeMd'] as string, 10) &&
      // this.resizedHeight <= parseInt(variables['breakpointTabletLandscapeXs'] as string, 10) &&
      isMobile.any
    ) {
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH : BONUS_BTN_CONTAINER_POSITION_X / 1.2;
    } else {
      this.x = this.isLeftHandMode && isMobile.any ? GAME_CONTAINER_WIDTH + 125 : -135;
    }
    this.y = this.isLeftHandMode ? 500 : 520;
  }
}

export default BuyBonusButton;
