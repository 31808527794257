import { Group, Layer } from '@pixi/layers';
import i18n from 'i18next';
import { Container, filters, type Application } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../config';
import { SlotId } from '../config/config';
import { Flow } from '../flow';
import { States } from '../flow/config';
import { EventTypes, UserBonus } from '../global.d';
import {
  setBetResult,
  setCurrentReelSetId,
  setIsRevokeThrowingError,
  setLastBetResult,
  setLastSpinData,
  setReelSets,
  setRestoreGame,
  setStressful,
} from '../gql/cache';
import { getBetResult, getLayerOrderByName, playBackgroundAudio } from '../utils';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BuyBonusBtn from './buyBonus/buyBonusButton';
import type { AbstractContainer } from './components/AbstractContainer';
import { eventEmitter, PopupTypes, REELS_AMOUNT } from './config';
import { type ISlotData } from './d';
import Fade from './fade/fade';
import Footer from './footer/footer';
import AutoplayButton from './gameButtons/autoplayButton';
import InfoButton from './gameButtons/infoButton';
import MenuButton from './gameButtons/menuButton';
import SoundButton from './gameButtons/soundButton';
import SpinButton from './gameButtons/spinButton';
import TurboButton from './gameButtons/turboButton';
import GameScreen from './gameScreen/gameScreen';
import GameWrapper from './gameWrapper/gameWrapper';
import MiniPayInfoContainer from './miniPayInfo/miniPayInfoContainer';
import BuyBonusPopup from './popups/buyBonus/buyBonusPopup';
import BuyBonusPopupConfirm from './popups/buyBonus/buyBonusPopupConfirm';
import { FreeSpinsEndPopup } from './popups/freeSpins/freeSpinsEndPopup';
import { FreeSpinsPopup } from './popups/freeSpins/freeSpinsPopup';
import { PopupModule } from './popups/PopupModule';
import ReelsBackground from './reels/background';
import { IReels } from './reels/d';
import Reels from './reels/reels';
import type Slot from './reels/slot';
import { SpecialWin } from './specialWin/specialWin';
import TintContainer from './tint/tintContainer';
import CountUp from './winAnimations/countUpAnimation';
import { SymbolAnimation } from './winAnimations/symbolAnimation';

class Game {
  public isStopped = false;

  public static initGame = (slotData: ISlotData): void => {
    Game.current = new Game(Flow.the.application, slotData);
  };

  public static the(): Game {
    return Game.current;
  }

  private static current: Game;

  private application: Application;

  private reelsContainer!: Reels;

  private miniPayInfoContainer!: MiniPayInfoContainer;

  public layer: Layer;

  public layersGroup: Group;

  public footer!: Footer;

  public gameScreen!: GameScreen;

  public background!: Background;

  private menuButton = new MenuButton();

  private soundButton = new SoundButton();

  private turboButton = new TurboButton();

  private spinButton = new SpinButton();

  private infoButton = new InfoButton();

  private autoPlayButton = new AutoplayButton();

  private gameWrapper = new GameWrapper();

  private constructor(application: Application, slotConfig: ISlotData) {
    this.application = application;
    this.application.stage.sortableChildren = true;
    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = getLayerOrderByName(layer.name);
    });
    this.layer = new Layer(this.layersGroup);

    this.application.stage.addChild(this.layer);
    this.initGameListeners();
    this.buildGame(slotConfig);
    eventEmitter.on(EventTypes.CLOSE_POPUP_BG, () => {
      this.handleBlur(false);
    });
    eventEmitter.on(EventTypes.OPEN_POPUP_BG, () => {
      this.handleBlur(true);
    });
  }

  private initGameListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      if (!setRestoreGame()) eventEmitter.emit(EventTypes.GAME_READY);
    });
    eventEmitter.addListener(EventTypes.SET_CURRENT_RESULT_MINI_PAYINFO, this.setCurrentResultMiniPayInfo.bind(this));
    eventEmitter.addListener(EventTypes.THROW_ERROR, Game.handleError);
    eventEmitter.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      AudioApi.stop({ type: ISongs.Background });
      playBackgroundAudio(Flow.the.controller.gameMode);
    });
  }

  private buildGame(slotConfig: ISlotData): void {
    const isLastBetPresent = setLastBetResult().id;
    setReelSets(slotConfig.reels);
    const startPosition = isLastBetPresent
      ? setLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;
    setLastSpinData({
      layout: [],
      reelPositions: startPosition,
    });

    const reelSet = isLastBetPresent
      ? slotConfig.reels.find((reelSet) => reelSet.id === setLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => {
          return (reelSet.type as unknown as string) === 'DEFAULT';
        })!;
    this.background = new Background();
    this.background.name = 'Background';
    this.background.parentGroup = this.layersGroup;
    this.reelsContainer = this.getReelsContainer(reelSet.layout, startPosition);
    const mysteryMatrix = setLastBetResult().id ? setLastBetResult().data.features?.mystery?.mysteryMatrix : [];
    this.miniPayInfoContainer = new MiniPayInfoContainer(
      slotConfig.icons,
      this.reelsContainer.getCurrentSpinResult(),
      this.getSlotById.bind(this),
      mysteryMatrix,
    );
    const specialWin = new SpecialWin();
    specialWin.name = 'SpecialWin';
    specialWin.parentGroup = this.layersGroup;
    this.gameScreen = new GameScreen({
      symbolAnimation: this.getSymbolAnimation(),
      reelsBackgroundContainer: new ReelsBackground(),
      reelsContainer: this.reelsContainer as unknown as IReels & AbstractContainer,
      tintContainer: new TintContainer(),
      countUp: new CountUp(),
      miniPayInfoContainer: this.miniPayInfoContainer,
    });
    this.menuButton.parentGroup = this.layersGroup;
    this.soundButton.name = 'ControlBtn';
    this.soundButton.parentGroup = this.layersGroup;
    this.turboButton.name = 'ControlBtn';
    this.turboButton.parentGroup = this.layersGroup;
    this.spinButton.name = 'ControlBtn';
    this.spinButton.parentGroup = this.layersGroup;
    this.infoButton.name = 'ControlBtn';
    this.infoButton.parentGroup = this.layersGroup;
    this.autoPlayButton.name = 'ControlBtn';
    this.autoPlayButton.parentGroup = this.layersGroup;
    this.gameWrapper.name = 'GameWrapper';
    this.gameWrapper.parentGroup = this.layersGroup;

    setCurrentReelSetId(reelSet.id);
    const freeSpinsPopup = new FreeSpinsPopup();
    const freeSpinsEndPopup = new FreeSpinsEndPopup();
    this.gameScreen.interactive = true;
    this.gameScreen.on('mousedown', () => {
      eventEmitter.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      this.skipWinAnimation();
    });
    this.gameScreen.on('touchstart', () => {
      eventEmitter.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      this.skipWinAnimation();
    });

    this.gameScreen.addChild(new BuyBonusBtn());
    this.gameScreen.addChild(this.buildBuyBonusPopupsContainer());

    PopupModule.the.registerPopup(PopupTypes.FREE_SPINS, freeSpinsPopup);
    PopupModule.the.registerPopup(PopupTypes.FREE_SPINS_END, freeSpinsEndPopup);

    this.gameWrapper.addChild(this.gameScreen);
    const backdrop = new Backdrop(EventTypes.OPEN_POPUP_BG, EventTypes.CLOSE_POPUP_BG);
    backdrop.name = 'Backdrop';
    backdrop.parentGroup = this.layersGroup;
    this.footer = new Footer();
    this.application.stage.addChild(
      this.background,
      backdrop,
      this.gameWrapper,
      this.footer,
      freeSpinsEndPopup,
      freeSpinsPopup,
      new Fade(),
      this.menuButton,
      this.soundButton,
      this.turboButton,
      this.spinButton,
      this.infoButton,
      this.autoPlayButton,
      specialWin,
    );
  }

  private handleBlur(isBlurred: boolean): void {
    const blurFilter = new filters.BlurFilter();
    blurFilter.blur = 15;
    const filter = isBlurred ? [blurFilter] : [];
    this.background.filters = filter;
    this.gameScreen.filters = filter;
    this.spinButton.filters = filter;
    this.menuButton.filters = filter;
    this.infoButton.filters = filter;
    this.autoPlayButton.filters = filter;
    this.turboButton.filters = filter;
    this.soundButton.filters = filter;
  }

  private skipWinAnimation(): void {
    if (Flow.the.state.name === States.AFTER_WIN || Flow.the.state.name === States.IDLE) {
      Flow.the.skipWinAnimation();
    }
  }

  private buildBuyBonusPopupsContainer(): Container {
    const container = new Container();
    const buyBonusPopup = new BuyBonusPopup();
    const buyBonusPopupConfirm = new BuyBonusPopupConfirm();
    PopupModule.the.registerPopup(PopupTypes.BUY_BONUS, buyBonusPopup);
    PopupModule.the.registerPopup(PopupTypes.BUY_BONUS_CONFIRMATION, buyBonusPopupConfirm);
    container.addChild(buyBonusPopup, buyBonusPopupConfirm);

    return container;
  }

  private getReelsContainer(reelSetLayout: SlotId[][], startPosition: number[]) {
    return new Reels(reelSetLayout, startPosition);
  }

  private getSymbolAnimation() {
    return new SymbolAnimation();
  }

  public onRestoreGame(bonus: UserBonus): void {
    eventEmitter.emit(EventTypes.RESTORE_GAME, bonus);
    eventEmitter.emit(EventTypes.GAME_READY);
  }

  private static handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  public spinSpinAnimation(): void {
    eventEmitter.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventEmitter.emit(EventTypes.SKIP_WIN_ANIMATION);
    eventEmitter.emit(EventTypes.START_SPIN_ANIMATION);
  }

  public getSlotAt(x: number, y: number): Slot | undefined {
    return this.reelsContainer.reels[x as number]!['slots'][y as number];
  }

  public getSlotById(id: number): Slot | undefined {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public setCurrentResultMiniPayInfo(): void {
    let mysteryMatrix;
    try {
      mysteryMatrix = getBetResult(setBetResult()).bet.data.features?.mystery?.mysteryMatrix;
    } catch {
      mysteryMatrix = setLastBetResult().id ? setLastBetResult().data.features?.mystery?.mysteryMatrix : [];
    }
    this.miniPayInfoContainer.setSpinResult(this.reelsContainer.getCurrentSpinResult(), mysteryMatrix);
  }
}

export default Game;
