import i18n from 'i18next';
import { Spine } from 'pixi-spine';
import { Container, isMobile, Loader, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { formatNumber } from '@money.energy/utils-fe';
import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setCurrency, setFreeSpinsTotalWin, setIsContinueAutoSpinsAfterFeature } from '../../../gql/cache';
import { normalizeCoins, showCurrency } from '../../../utils';
import { TextField } from '../../components/TextField';
import { eventEmitter } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';
import { congratulationTextStyle, textWinAmount, textYouWon } from '../textStyles';

export class FreeSpinsEndPopup extends Popup {
  protected background!: Sprite;

  protected animation!: Spine;

  protected winValue!: TextField;

  protected youWonText!: TextField;

  protected congratulationText!: TextField;

  protected pressToContinueText!: TextField;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.initBackground();
    this.initAnimation();
    this.initCongratulationText();
    this.initYouWonText();
    this.initWinValueText();

    this.init();
  }

  private initAnimation(): void {
    this.animation = new Spine(Loader.shared.resources['fs-popup']!.spineData!);
  }

  private initBackground(): void {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.background.alpha = 0.5;
  }

  private initYouWonText(): void {
    this.youWonText = new TextField(i18n.t('freeSpinsYouWon'), 900, 400, textYouWon);
    this.youWonText.text.anchor.set(0.5, 0.5);
    this.youWonText.text.y = -50;
    this.animation.addChild(this.youWonText.getText());
  }

  private initCongratulationText(): void {
    this.congratulationText = new TextField(i18n.t('freeSpinsCongratulation'), 900, 400, congratulationTextStyle);
    this.congratulationText.text.anchor.set(0.5, 0.5);
    this.congratulationText.text.y = -250;
  }

  private initWinValueText(): void {
    this.winValue = new TextField('', 900, 400, textWinAmount);
    this.winValue.text.anchor.set(0.5, 0.5);
    this.winValue.text.y = 150;
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.animation);
    const content = new Container();
    content.addChild(this.congratulationText.text, this.youWonText.text, this.winValue.text);
    this.animation.skeleton.findSlot('place_holder').currentSprite.texture = Texture.EMPTY;
    this.animation.skeleton.findSlot('place_holder').currentSprite.addChild(content);
  }

  public override show(_props?: PopupProps): void {
    AudioApi.stop({ type: ISongs.FreeSpinsBackground });
    AudioApi.play({ type: ISongs.FreeSpinEnd });
    this.winValue.setText(
      `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`,
    );
    this.visible = true;
    this.animation.state.setAnimation(0, 'FS_popup_intro', false);
    this.animation.state.addAnimation(0, 'FS_popup_cycle', true, 0);
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventEmitter.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    AudioApi.stop({ type: ISongs.FreeSpinEnd });
    AudioApi.play({ type: ISongs.FreeSpinClose });
    eventEmitter.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    clearTimeout(this.autoSkipDelay);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.hideAnimation();
  };

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    const factor = (isMobile.any && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.animation.scale.set(factor);
    this.position.set(width / 2, height / 2);
    this.animation.y = isMobile.any && !isLandscape ? -50 : 0;
  }

  public hideAnimation(): void {
    this.animation.state.setAnimation(0, 'FS_popup_outro', false);
    this.animation.state.addListener({
      complete: (_entry: { trackIndex: number }) => {
        this.visible = false;
        eventEmitter.emit(EventTypes.END_FREE_SPINS);
      },
    });
  }
}
