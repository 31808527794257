import i18n from 'i18next';
import { Spine } from 'pixi-spine';
import { isMobile, ITextStyle, Loader, Sprite, Texture } from 'pixi.js';
import AudioApi from '@money.energy/audio-api';
import { ISongs } from '../../../config';
import { Flow } from '../../../flow';
import { States } from '../../../flow/config';
import { RegularModule } from '../../../flow/modules/RegularModule';
import { BonusState, EventTypes } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { getBonusIdByBonus } from '../../../utils';
import {
  buyBonusSubTitleStyle,
  buyBonusTitleStyleB,
  buyBonusTotalCost,
  totalCostValueTextStyle,
} from '../../buyBonus/textStyles';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { BONUS_POPUP_HEIGHT, BONUS_POPUP_WIDTH, eventEmitter, PopupTypes } from '../../config';
import { BuyBonusConfirmProps } from '../../d';
import { Popup } from '../popup';
import { PopupModule } from '../PopupModule';

class BuyBonusPopupConfirm extends Popup {
  private background: Sprite;

  private animation!: Spine;

  private bonusState: BonusState = BonusState.FREE_SPINS;

  private titleText: TextField;

  private subTitleText: TextField;

  private subTitleBackplate: Sprite;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private okBtn: Sprite;

  private cancelBtn: Sprite;

  constructor() {
    super();
    this.x = 145;
    this.y = 190;
    this.visible = false;
    this.background = this.initBackground();
    this.titleText = this.initTitle();
    this.subTitleText = this.initSubTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.subTitleBackplate = this.initSubTitleBackplate();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    // this.animation = this.initAnimation();
    this.init();
    this.scale.set(0.88);
    eventEmitter.on(EventTypes.FORCE_CLOSE_BUYBONUS, () => {
      this.handleClose(true);
    });
  }

  public override show(props: BuyBonusConfirmProps): void {
    super.show();
    const { bonusState, totalCost } = props;
    this.titleText.setText(i18n.t<string>('buyBonusConfirmTitleFreeSpins'));
    this.subTitleText.setText(i18n.t<string>('buyBonusConfirmTitleFreeSpins'));
    this.totalCostText.setStyle(bonusState === BonusState.FREE_SPINS ? buyBonusTitleStyleB : buyBonusTitleStyleB);
    this.totalCostValue.setStyle(
      bonusState === BonusState.FREE_SPINS ? totalCostValueTextStyle : totalCostValueTextStyle,
    );
    this.bonusState = bonusState;
    this.visible = true;
    this.totalCostValue.text.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private init(): void {
    this.addChild(this.background);
    this.addChild(this.titleText.getText());
    this.addChild(this.subTitleBackplate);
    this.addChild(this.subTitleText.getText());
    this.addChild(this.totalCostText.getText());
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources['buyBonusPopups']!.spineData!);
    animation.state.setAnimation(0, 'buy_feature_popup_confirm_dark_to_light', true);
    animation.x = 640;
    animation.y = 380;
    animation.scale.set(1.35);
    this.background.addChild(animation);
    return animation;
  }

  private initBackground(): Sprite {
    const background = new Sprite(Texture.from(ResourceTypes.betMinusBtnHover));
    background.anchor.set(0.5, 0.5);
    background.width = isMobile.any ? 1150 : BONUS_POPUP_WIDTH;
    background.height = isMobile.any ? 925 : BONUS_POPUP_HEIGHT;
    background.x = isMobile.any ? 551 : 560;
    background.y = isMobile.any ? 358 : 355;

    return background;
  }

  private initTitle(): TextField {
    const title = new TextField(
      i18n.t<string>('buyBonusConfirmTitleBase'),
      1300,
      300,
      buyBonusTitleStyleB as Partial<ITextStyle>,
    );
    title.text.y = 35;
    title.text.x = 555;
    title.text.anchor.set(0.5, 0.5);
    return title;
  }

  private initSubTitle(): TextField {
    const subTitle = new TextField(
      i18n.t<string>('buyBonusConfirmTitleBase'),
      1300,
      300,
      buyBonusSubTitleStyle as Partial<ITextStyle>,
    );
    subTitle.text.y = 215;
    subTitle.text.x = 555;
    subTitle.text.anchor.set(0.5, 0.5);
    return subTitle;
  }

  private initTotalCostText(): TextField {
    const titleCost = new TextField(
      i18n.t<string>('buyBonusTotalCost'),
      1300,
      300,
      buyBonusTitleStyleB as Partial<ITextStyle>,
    );
    titleCost.text.y = 360;
    titleCost.text.x = 555;

    titleCost.text.anchor.set(0.5, 0.5);

    return titleCost;
  }

  private initTotalCostValue(): TextField {
    const totalCostValue = new TextField('0', 500, 100, buyBonusTotalCost as Partial<ITextStyle>);
    totalCostValue.text.y = 475;
    totalCostValue.text.x = 555;
    totalCostValue.text.anchor.set(0.5, 0.5);
    return totalCostValue;
  }

  private initSubTitleBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.betPlusBtn));
    input.y = 220;
    input.x = 555;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.UI_ButtonClose });
      },
      onTouchStart: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.UI_ButtonClose });
      },
    });
    cancelBtn.anchor.set(0.5, 0.5);
    cancelBtn.y = 663;
    cancelBtn.x = isMobile.any ? 125 : 157;
    cancelBtn.width = 116;
    cancelBtn.height = 116;
    return cancelBtn;
  }

  private initOkBtn(): Sprite {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.betMinusBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.UI_ButtonHover }),
      onClick: () => {
        // AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyBonus();
      },
      onTouchStart: () => {
        // AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyBonus();
      },
    });

    okBtn.anchor.set(0.5, 0.5);
    okBtn.y = 663;
    okBtn.x = isMobile.any ? 985 : 965;
    okBtn.width = 116;
    okBtn.height = 116;
    return okBtn;
  }

  private startBuyBonus(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventEmitter.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByBonus(this.bonusState));
  }

  public handleClose(closeBuyBonus?: boolean): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    if (closeBuyBonus) {
      AudioApi.play({ type: ISongs.UI_ButtonClose });
      eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, false);
      Flow.the.changeState(States.IDLE);
      Flow.the.controller = RegularModule.the;
      Flow.the.controller.enterModule(null);

      PopupModule.the.closeCurrentPopup();
    } else {
      PopupModule.the.openPopup(PopupTypes.BUY_BONUS);
    }
  }
}

export default BuyBonusPopupConfirm;
