import { ITextStyle } from 'pixi.js';
import { EventTypes, GameMode } from '../../global.d';
import { AbstractContainer } from '../components/AbstractContainer';
import { TextField } from '../components/TextField';
import { eventEmitter } from '../config';

const titleTextStyle: Partial<ITextStyle> = {
  fill: '#C0F4FF',
  fontFamily: 'Noto Sans',
  fontSize: 40,
  lineJoin: 'round',
  miterLimit: 40,
  stroke: '#213A73',
  strokeThickness: 15,
  whiteSpace: 'normal',
  fontWeight: '900',
};

const fsCountTextStyle: Partial<ITextStyle> = {
  fill: '#FFFFFF',
  fontFamily: 'Noto Sans',
  fontSize: 45,
  lineJoin: 'round',
  miterLimit: 45,
  stroke: '#213A73',
  strokeThickness: 15,
  whiteSpace: 'normal',
  fontWeight: '900',
};
export class FSCounter extends AbstractContainer {
  private title = new TextField('FREE SPINS', 300, 100, titleTextStyle);
  private spins = new TextField('0/0', 300, 100, fsCountTextStyle);

  constructor() {
    super();
    this.x = 0;
    this.y = -450;
    this.name = 'FS Counter';
    this.visible = true;
    this.title.text.y = -40;
    this.spins.text.y = 15;
    this.title.text.anchor.set(0.5);
    this.spins.text.anchor.set(0.5);
    this.addChild(this.title.getText(), this.spins.getText());
    eventEmitter.addListener(EventTypes.UPDATE_FREE_SPINS_AMOUNT, this.updateSpins.bind(this));
  }

  protected override onGameModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = false;
        break;
      case GameMode.FREE_SPINS:
        this.visible = true;
        break;
      default:
        this.visible = false;
        break;
    }
  }

  private updateSpins(current: number, total: number): void {
    this.spins.setText(`${current}/${total}`);
    this.spins.setStyle({
      ...fsCountTextStyle,
    });
  }
}
