export enum ResourceTypes {
  backdrop = 'backdrop',
  betBackground = 'betBackground',
  betMinusBtn = 'betMinusBtn',
  betMinusBtnDisable = 'betMinusBtnDisable',
  betMinusBtnHover = 'betMinusBtnHover',
  betMinusBtnPressed = 'betMinusBtnPressed',
  betPlusBtn = 'betPlusBtn',
  betPlusBtnDisable = 'betPlusBtnDisable',
  betPlusBtnHover = 'betPlusBtnHover',
  betPlusBtnPressed = 'betPlusBtnPressed',
  frameFs = 'frameFs',
  frame = 'frame',
  infoPaylines = 'infoPaylines',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  logo = 'logo',
  payinfoScatter = 'payinfoScatter',
  payinfoWild = 'payinfoWild',
  reelBackground = 'reelBackground',
  slotBgFs = 'slotBgFs',
  slotBg = 'slotBg',
  high1 = 'high1',
  high1Motion = 'high1Motion',
  high2 = 'high2',
  high2Motion = 'high2Motion',
  high3 = 'high3',
  high3Motion = 'high3Motion',
  high4 = 'high4',
  high4Motion = 'high4Motion',
  high5 = 'high5',
  high5Motion = 'high5Motion',
  low1 = 'low1',
  low1Motion = 'low1Motion',
  low2 = 'low2',
  low2Motion = 'low2Motion',
  low3 = 'low3',
  low3Motion = 'low3Motion',
  low4 = 'low4',
  low4Motion = 'low4Motion',
  low5 = 'low5',
  low5Motion = 'low5Motion',
  mysteryBlue = 'mysteryBlue',
  mysteryBrown = 'mysteryBrown',
  mysteryGold = 'mysteryGold',
  scatter = 'scatter',
  scatterMotion = 'scatterMotion',
  wild = 'wild',
  wildMotion = 'wildMotion',
}
