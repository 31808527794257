import { Container, Graphics } from 'pixi.js';
import { EventTypes, GameMode } from '../../global.d';
import { isMobileLandscape, isMobilePortrait, isTabletLandscape, isTabletPortrait } from '../../utils';
import { AbstractContainer } from '../components/AbstractContainer';
import { eventEmitter, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';
import type { IGameContainer } from '../d';
import { IReels } from '../reels/d';
// import FreeSpinsPopup from '../popups/freeSpinsPopup/freeSpinsPopup';
import Frame from '../reels/frame';
import type CountUp from '../winAnimations/countUpAnimation';
import { FSCounter } from './fsCounter';
import Logo from './logo';

class GameScreen extends AbstractContainer {
  public symbolAnimation: Container;

  public miniPayInfoContainer: Container;

  public reelsBackgroundContainer: Container;

  public reelsContainer: IReels & AbstractContainer;

  public tintContainer: Container;

  public slotsContainer: Container;

  public countUp: CountUp;

  public frame: Frame;

  public gameLogo: Logo;

  public maskArea: Graphics;

  private fsCounter: FSCounter;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.slotsContainer = new Container();
    this.slotsContainer.name = 'slotsContainer';
    this.slotsContainer.x = -SLOTS_CONTAINER_WIDTH / 2;
    this.slotsContainer.y = -SLOTS_CONTAINER_HEIGHT / 2;
    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();

    this.slotsContainer.interactive = true;
    this.gameLogo = new Logo();
    this.fsCounter = new FSCounter();
    this.symbolAnimation = props.symbolAnimation;
    this.symbolAnimation.y = this.slotsContainer.y;
    this.symbolAnimation.x = this.slotsContainer.x;
    this.tintContainer = props.tintContainer;
    this.miniPayInfoContainer = props.miniPayInfoContainer;
    this.miniPayInfoContainer.x = this.slotsContainer.x;
    this.miniPayInfoContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.countUp = props.countUp;
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.frame = new Frame();
    this.addChild(
      this.frame,
      this.slotsContainer,
      this.miniPayInfoContainer,
      this.gameLogo,
      this.fsCounter,
      this.symbolAnimation,
      this.countUp,
    );
    eventEmitter.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    // eventEmitter.addListener(EventTypes.CREATE_FREESPIN_POPUP, this.createFreeSpinsMessage.bind(this));
  }

  protected override onGameModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    // handleBaseModeChanges
  }

  private resizeGameContainer(width: number, height: number, deviceWidth: number, deviceHeight: number): void {
    this.scale.set(width / 1620);
    this.y = height * 0.03;

    if (isTabletLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1750);
    }
    if (isTabletPortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1510);
      this.y = -height * 0.07;
    }
    if (isMobilePortrait(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1470);
      this.y = -height * 0.1;
    }
    if (isMobileLandscape(deviceWidth, deviceHeight)) {
      this.scale.set(width / 1550);
      this.y = -height * 0.01;
    }
    this.x = width / 2;
  }
}

export default GameScreen;
