import _ from 'lodash';
import { Container, Graphics } from 'pixi.js';
import { config, MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import {
  eventEmitter,
  REEL_WIDTH,
  REELS_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
} from '../config';
import type { Combos, Icon, MysteryMatrix } from '../d';
import type Slot from '../reels/slot';
import MiniPayInfo from './miniPayInfo';

class MiniPayInfoContainer extends Container {
  private getSlotById: CallableFunction;

  private payInfosContainers: Container[];

  private combosMap: Record<SlotId, Combos>;

  private icons: Icon[] = [];

  constructor(
    icons: Icon[],
    initialPayInfoConfig: Icon[],
    getSlotByIdFn: CallableFunction,
    mysteryMatrix?: MysteryMatrix[],
  ) {
    super();
    this.icons = icons;
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.zIndex = 1;
    this.getSlotById = getSlotByIdFn;
    eventEmitter.on(EventTypes.DISABLE_PAYINFO, () => this.disableMiniPayInfo(false));
    eventEmitter.on(EventTypes.ENABLE_PAYINFO, () => this.disableMiniPayInfo(true));
    this.payInfosContainers = [];

    this.combosMap = _.mapValues(
      MAPPED_SYMBOLS,
      (slug) => config.payInfoData.find((d) => d.slug === slug.default)?.combos as Combos,
    );
    this.createContainers();
    this.setSpinResult(initialPayInfoConfig, mysteryMatrix);
  }

  private disableMiniPayInfo(isDisable: boolean): void {
    this.visible = isDisable;
  }

  private createContainers() {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const slot = this.getSlotById(index as number) as Slot;
        const icon = this.icons.find((icon) => icon.id === slot.slotId)!;
        const container = new Container();
        const rect = new Graphics();
        container.width = 300;
        container.height = 240;
        container.x = this.xPosition(row, col);
        container.y = this.yPosition(row, col);
        container.zIndex = 1;
        container.interactive = true;
        rect.beginFill(0x000000);
        rect.drawRect(0, 0, SLOT_WIDTH, SLOT_HEIGHT);
        rect.alpha = 0;
        container.addChild(rect);
        container.on('mousedown', () => {
          eventEmitter.emit(EventTypes.SHOW_PAY_INFO, index);
        });
        container.on('touchstart', () => eventEmitter.emit(EventTypes.SHOW_PAY_INFO, index));
        const payInfo = new MiniPayInfo(index, icon, this.combosMap[icon.id]);
        container.addChild(payInfo);
        this.payInfosContainers.push(container);
        this.addChild(container);
      }
    }
  }

  private xPosition(_row: number, col: number): number {
    const positionValue = REEL_WIDTH;
    return col * positionValue;
  }

  private yPosition(row: number, _col: number): number {
    return SLOT_HEIGHT * row;
  }

  public setSpinResult(spinResult: Icon[], mysteryMatrix?: MysteryMatrix[]): void {
    spinResult.forEach((icon, index: number) => {
      const container = this.payInfosContainers[index as number] as Container;
      const payInfo = container.children[1] as MiniPayInfo;
      let iconId = icon.id;
      if (mysteryMatrix?.length && icon.id === SlotId.MS1) {
        iconId = mysteryMatrix![index].changeTo;
      }
      payInfo.setPayInfoData(iconId, this.combosMap[iconId as SlotId]);
    });
    this.visible = true;
  }
}

export default MiniPayInfoContainer;
