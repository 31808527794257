import { Stage } from '@pixi/layers';
import { Application } from 'pixi.js';
import { SlotId } from '../config';
import Animator from '../game/animations/animator';
import { eventEmitter, GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH } from '../game/config';
import type { Icon } from '../game/d';
import { EnterProps, EventTypes, GameMode } from '../global.d';
import { setBetResult, setCurrentBonus, setIsAutoSpins } from '../gql/cache';
import { IPixiViewParentNode, States } from './config';
import type { AbstractModule } from './modules/AbstractModule';
import { BuyBonusModule } from './modules/BuyBonusModule';
import { FreeSpinModule } from './modules/FreeSpinModule';
import { RegularModule } from './modules/RegularModule';
import { AfterWin, BeforeWin, HighlightWin, Idle, Init, Intro, Jingle, Spin, Transition } from './states';
import { Mystery } from './states/Mystery';
import { RestoreGame } from './states/RestoreGame';
import type { State } from './states/State';

export class Flow {
  public state: State = Init.the;

  public stopped = false;

  public isReadyForStop = false;

  public isStoppedBeforeResult = false;

  public controller: AbstractModule;

  public static the = new Flow();

  public application: Application;

  public animator: Animator;

  public currentSpinResult: Icon[] | null = null;

  get isReadyToSpin(): boolean {
    return this.controller.gameMode === GameMode.BASE_GAME && this.state.name === States.IDLE;
  }

  get isReadyToStop(): boolean {
    return this.state.name === States.SPIN;
  }

  get isReadyToSkip(): boolean {
    return this.state.name === States.AFTER_WIN;
  }

  get isStopped(): boolean {
    return this.stopped;
  }

  private constructor() {
    this.registerStates();
    this.application = new Application({
      resolution: Math.min(2, window.devicePixelRatio || 1),
      autoDensity: true,
      backgroundAlpha: 0,
      width: GAME_CONTAINER_WIDTH,
      height: GAME_CONTAINER_HEIGHT,
    });

    this.initPixiDebugExtension();

    this.application.stage = new Stage();
    this.animator = new Animator(this.application);
    this.controller = RegularModule.the;
    this.controller.enterModule(null);
  }

  private initPixiDebugExtension(): void {
    (globalThis as unknown as { __PIXI_APP__: unknown }).__PIXI_APP__ = this.application;
  }

  public init(): void {
    window.addEventListener(EventTypes.RESIZE, this.resize.bind(this));
    eventEmitter.on(EventTypes.FORCE_RESIZE, this.handleResize.bind(this));

    eventEmitter.on(EventTypes.REELS_STOPPED, () => {
      const result = this.getCurrentSpinResult();
      const mysterySymbol = result.find((icon: Icon) => icon.id === SlotId.MS1);
      if (mysterySymbol) {
        this.changeState(States.MYSTERY);
      } else {
        this.changeState(States.BEFORE_WIN);
      }
    });
    this.state = Init.the;
    this.state.enterState(States.INIT);
  }

  private registerStates(): void {
    Init.the.nodes.set(States.IDLE, Idle.the);
    Init.the.nodes.set(States.RESTORE_GAME, RestoreGame.the);
    Init.the.nodes.set(States.INTRO, Intro.the);

    Intro.the.nodes.set(States.RESTORE_GAME, RestoreGame.the);
    Intro.the.nodes.set(States.IDLE, Idle.the);

    RestoreGame.the.nodes.set(States.TRANSITION, Transition.the);
    RestoreGame.the.nodes.set(States.IDLE, Idle.the);

    Idle.the.nodes.set(States.SPIN, Spin.the);
    Idle.the.nodes.set(States.TRANSITION, Transition.the);

    Spin.the.nodes.set(States.BEFORE_WIN, BeforeWin.the);
    Spin.the.nodes.set(States.MYSTERY, Mystery.the);
    Spin.the.nodes.set(States.IDLE, Idle.the);

    Mystery.the.nodes.set(States.BEFORE_WIN, BeforeWin.the);
    Mystery.the.nodes.set(States.IDLE, Idle.the);

    BeforeWin.the.nodes.set(States.IDLE, Idle.the);
    BeforeWin.the.nodes.set(States.HighlightWin, HighlightWin.the);

    HighlightWin.the.nodes.set(States.AFTER_WIN, AfterWin.the);

    AfterWin.the.nodes.set(States.JINGLE, Jingle.the);

    Jingle.the.nodes.set(States.TRANSITION, Transition.the);
    Jingle.the.nodes.set(States.IDLE, Idle.the);

    Transition.the.nodes.set(States.IDLE, Idle.the);
  }

  public changeState(nextState: States): void {
    this.state.exitState(nextState);
    if (!this.state.nodes.has(nextState)) throw Error(`Invalid change state from ${this.state.name} to ${nextState}`);
    const currentState = this.state.name;
    this.state = this.state.nodes.get(nextState)!;
    this.state.enterState(currentState);
  }

  public spin(): void {
    this.isReadyForStop = false;
    this.isStoppedBeforeResult = false;
    setBetResult(null);
    this.changeState(States.SPIN);
  }

  public handleInsufficientFunds(): void {
    eventEmitter.emit(EventTypes.ROLLBACK_REELS);
    if (setIsAutoSpins()) setIsAutoSpins(false);
    this.changeState(States.IDLE);
  }

  public quickStop(): void {
    if (this.isReadyForStop) {
      eventEmitter.emit(EventTypes.FORCE_STOP_REELS);
    } else {
      this.isStoppedBeforeResult = true;
    }
  }

  public getCurrentSpinResult(): Icon[] {
    if (this.currentSpinResult === null) throw new Error('NO CURRENT SPIN RESULT');
    return this.currentSpinResult;
  }

  public skipWinAnimation(): void {
    eventEmitter.emit(EventTypes.SKIP_WIN_ANIMATION);
  }
  public changeGameMode(nextGameMode: GameMode, enterProps?: EnterProps): void {
    if (this.state.name !== States.TRANSITION) throw new Error('WRONG STATE FOR CHANGING MODE');
    const currentGameMode = this.controller.gameMode;
    let nextController: AbstractModule;
    switch (nextGameMode) {
      case GameMode.BASE_GAME:
        nextController = RegularModule.the;
        break;
      case GameMode.BUY_BONUS:
        nextController = BuyBonusModule.the;
        break;
      case GameMode.FREE_SPINS:
        setCurrentBonus({ ...setCurrentBonus(), isActive: true });
        nextController = FreeSpinModule.the;
        break;
      default:
        nextController = RegularModule.the;
        break;
    }
    if (
      nextController === BuyBonusModule.the ||
      enterProps?.immediate ||
      currentGameMode === BuyBonusModule.the.gameMode
    ) {
      this.controller.exitModule(nextGameMode);
      this.controller = nextController;
      eventEmitter.emit(EventTypes.CHANGE_MODE, { mode: nextGameMode });
      this.controller.enterModule(currentGameMode, enterProps);
      return;
    }
    eventEmitter.emit(EventTypes.START_MODE_CHANGE_FADE, () => {
      this.controller.exitModule(nextGameMode);
      eventEmitter.emit(EventTypes.CHANGE_MODE, { mode: nextGameMode });

      this.controller = nextController;
      this.controller.enterModule(currentGameMode);
    });
  }

  private handleResize(): void {
    const parent = this.application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventEmitter.emit(EventTypes.RESIZE, width, height);
    this.application.renderer.resize(width, height);
  }

  private resize(): void {
    const userAgent = navigator.userAgent;
    // resize fix for Chrome browsers on Ios devices
    if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
      setTimeout(() => {
        this.handleResize();
      }, 50);
    } else {
      this.handleResize();
    }
  }
}
